:root {
  --background-light: #ffffff;
  --background-dark: #121212;
  --text-light: #000000;
  --text-dark: #ffffff;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  background-color: var(--background-light);
  color: var(--text-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App.dark {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header-left,
.header-center,
.header-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-left {
  justify-content: flex-start;
}

.header-right {
  justify-content: flex-end;
}

.gallery-title {
  text-decoration: none;
  color: inherit;
  font-size: 1.5rem;
  font-weight: bold;
}

.cluster-button {
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 2px solid currentColor;
  border-radius: 5px;
  background: none;
}

.map-button {
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 2px solid currentColor;
  border-radius: 5px;
  background: none;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background: none;
  border: 2px solid currentColor;
  color: inherit;
  border-radius: 5px;
}

button:hover,
.cluster-button:hover,
.about-button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding: 10px;
}

.masonry-item {
  width: 100%;
}

.masonry-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease-in-out;
}

.masonry-item img:hover {
  border: 2px solid #007BFF;
}

.full-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px); /* Adjust according to header height */
  margin: 20px 0;
}

.full-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.full-image.scaled-down {
  max-height: 70%;
}

.arrow {
  position: absolute;
  top: 40%;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 50%;
  z-index: 1;
}

.arrow.left {
  left: 20px;
}

.arrow.right {
  right: 20px;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.find-similar-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background: none;
  border: 2px solid currentColor;
  color: inherit;
  border-radius: 5px;
}

.similar-thumbnails {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.similar-thumbnails .thumbnail {
  margin: 5px;
  width: 100px;
  height: auto;
  cursor: pointer;
}

.cluster-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding: 10px;
}

.cluster-item {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.cluster-name {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.cluster-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}


.image-overlay {
  pointer-events: none; /* Makes sure that the image overlay does not interfere with interaction */
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.help-button {
  display: inline-block;
}

.question-mark-icon {
  height: 2em;  /* Match the font size of the button */
  width: auto;  /* Keep the aspect ratio */
  cursor: pointer;
  vertical-align: middle;  /* Align it vertically with the button text */
}

.blog-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.section-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.latex-content {
  flex: 1;
  margin-right: 20px;
}

.video-content {
  width: 50%;
  height: auto;
  margin-left: 20px;
}

.blog-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.blog-container h2 {
  font-size: 1.5rem;  /* Slightly smaller headlines for sections */
  margin-top: 20px;
}

.blog-container h3 {
  font-size: 1.25rem;  /* Slightly smaller headlines for sections */
  margin-top: 15px;
}

.spacer {
  height: 100px;  /* Adjust this value to change the spacing */
}

.empty-container {
  width: 50%;   /* Fixed width of 600px */
  height: 1px;  /* Height can be adjusted as needed */
  background-color: #121212; /* Light gray background for visibility */
  margin-left: 20px; /* Space between text and placeholder */
}